exports.components = {
  "component---src-components-blog-blog-list-js": () => import("./../../../src/components/Blog/blogList.js" /* webpackChunkName: "component---src-components-blog-blog-list-js" */),
  "component---src-components-blog-blog-post-js-content-file-path-blog-5-dolog-amit-az-uj-gyakorloknak-erdemes-megtanulnia-md": () => import("./../../../src/components/Blog/blogPost.js?__contentFilePath=/opt/build/repo/blog/5-dolog-amit-az-uj-gyakorloknak-erdemes-megtanulnia.md" /* webpackChunkName: "component---src-components-blog-blog-post-js-content-file-path-blog-5-dolog-amit-az-uj-gyakorloknak-erdemes-megtanulnia-md" */),
  "component---src-components-blog-blog-post-js-content-file-path-blog-a-bandha-faja-mozgas-a-foldhoz-kapcsolodva-md": () => import("./../../../src/components/Blog/blogPost.js?__contentFilePath=/opt/build/repo/blog/a-bandha-faja-mozgas-a-foldhoz-kapcsolodva.md" /* webpackChunkName: "component---src-components-blog-blog-post-js-content-file-path-blog-a-bandha-faja-mozgas-a-foldhoz-kapcsolodva-md" */),
  "component---src-components-blog-blog-post-js-content-file-path-blog-a-bandhak-csodalatos-vilaga-md": () => import("./../../../src/components/Blog/blogPost.js?__contentFilePath=/opt/build/repo/blog/a-bandhak-csodalatos-vilaga.md" /* webpackChunkName: "component---src-components-blog-blog-post-js-content-file-path-blog-a-bandhak-csodalatos-vilaga-md" */),
  "component---src-components-blog-blog-post-js-content-file-path-blog-a-gondolkodas-szerepe-az-astanga-joga-gyakorlasaban-andy-davis-es-iain-grysak-beszelgetese-md": () => import("./../../../src/components/Blog/blogPost.js?__contentFilePath=/opt/build/repo/blog/a-gondolkodas-szerepe-az-astanga-joga-gyakorlasaban-andy-davis-es-iain-grysak-beszelgetese.md" /* webpackChunkName: "component---src-components-blog-blog-post-js-content-file-path-blog-a-gondolkodas-szerepe-az-astanga-joga-gyakorlasaban-andy-davis-es-iain-grysak-beszelgetese-md" */),
  "component---src-components-blog-blog-post-js-content-file-path-blog-a-harmadik-sorozat-ujrakezdese-elmelkedesek-egy-11-eves-kapcsolatrol-md": () => import("./../../../src/components/Blog/blogPost.js?__contentFilePath=/opt/build/repo/blog/a-harmadik-sorozat-ujrakezdese-elmelkedesek-egy-11-eves-kapcsolatrol.md" /* webpackChunkName: "component---src-components-blog-blog-post-js-content-file-path-blog-a-harmadik-sorozat-ujrakezdese-elmelkedesek-egy-11-eves-kapcsolatrol-md" */),
  "component---src-components-blog-blog-post-js-content-file-path-blog-a-jo-a-ross-es-az-astanga-joga-interju-egy-negyed-evszazados-utrol-md": () => import("./../../../src/components/Blog/blogPost.js?__contentFilePath=/opt/build/repo/blog/a-jo-a-ross-es-az-astanga-joga-interju-egy-negyed-evszazados-utrol.md" /* webpackChunkName: "component---src-components-blog-blog-post-js-content-file-path-blog-a-jo-a-ross-es-az-astanga-joga-interju-egy-negyed-evszazados-utrol-md" */),
  "component---src-components-blog-blog-post-js-content-file-path-blog-a-joga-egyszerusegenek-megorzese-ross-stambaugh-md": () => import("./../../../src/components/Blog/blogPost.js?__contentFilePath=/opt/build/repo/blog/a-joga-egyszerusegenek-megorzese-ross-stambaugh.md" /* webpackChunkName: "component---src-components-blog-blog-post-js-content-file-path-blog-a-joga-egyszerusegenek-megorzese-ross-stambaugh-md" */),
  "component---src-components-blog-blog-post-js-content-file-path-blog-a-kombucha-tea-md": () => import("./../../../src/components/Blog/blogPost.js?__contentFilePath=/opt/build/repo/blog/a-kombucha-tea.md" /* webpackChunkName: "component---src-components-blog-blog-post-js-content-file-path-blog-a-kombucha-tea-md" */),
  "component---src-components-blog-blog-post-js-content-file-path-blog-a-legzes-legtokeletesebb-modja-az-astanga-jogaban-md": () => import("./../../../src/components/Blog/blogPost.js?__contentFilePath=/opt/build/repo/blog/a-legzes-legtokeletesebb-modja-az-astanga-jogaban.md" /* webpackChunkName: "component---src-components-blog-blog-post-js-content-file-path-blog-a-legzes-legtokeletesebb-modja-az-astanga-jogaban-md" */),
  "component---src-components-blog-blog-post-js-content-file-path-blog-a-versengesrol-md": () => import("./../../../src/components/Blog/blogPost.js?__contentFilePath=/opt/build/repo/blog/a-versengesrol.md" /* webpackChunkName: "component---src-components-blog-blog-post-js-content-file-path-blog-a-versengesrol-md" */),
  "component---src-components-blog-blog-post-js-content-file-path-blog-astanga-dristi-md": () => import("./../../../src/components/Blog/blogPost.js?__contentFilePath=/opt/build/repo/blog/astanga-dristi.md" /* webpackChunkName: "component---src-components-blog-blog-post-js-content-file-path-blog-astanga-dristi-md" */),
  "component---src-components-blog-blog-post-js-content-file-path-blog-astanga-egy-eleten-at-md": () => import("./../../../src/components/Blog/blogPost.js?__contentFilePath=/opt/build/repo/blog/astanga-egy-eleten-at.md" /* webpackChunkName: "component---src-components-blog-blog-post-js-content-file-path-blog-astanga-egy-eleten-at-md" */),
  "component---src-components-blog-blog-post-js-content-file-path-blog-astanga-ikonok-sri-k-pattabhi-jois-1-resz-md": () => import("./../../../src/components/Blog/blogPost.js?__contentFilePath=/opt/build/repo/blog/astanga-ikonok-sri-k-pattabhi-jois-1-resz.md" /* webpackChunkName: "component---src-components-blog-blog-post-js-content-file-path-blog-astanga-ikonok-sri-k-pattabhi-jois-1-resz-md" */),
  "component---src-components-blog-blog-post-js-content-file-path-blog-astanga-ikonok-sri-k-pattabhi-jois-2-resz-md": () => import("./../../../src/components/Blog/blogPost.js?__contentFilePath=/opt/build/repo/blog/astanga-ikonok-sri-k-pattabhi-jois-2-resz.md" /* webpackChunkName: "component---src-components-blog-blog-post-js-content-file-path-blog-astanga-ikonok-sri-k-pattabhi-jois-2-resz-md" */),
  "component---src-components-blog-blog-post-js-content-file-path-blog-astanga-ikonok-sri-k-pattabhi-jois-3-resz-md": () => import("./../../../src/components/Blog/blogPost.js?__contentFilePath=/opt/build/repo/blog/astanga-ikonok-sri-k-pattabhi-jois-3-resz.md" /* webpackChunkName: "component---src-components-blog-blog-post-js-content-file-path-blog-astanga-ikonok-sri-k-pattabhi-jois-3-resz-md" */),
  "component---src-components-blog-blog-post-js-content-file-path-blog-astanga-ikonok-t-krishnamacharya-1-resz-md": () => import("./../../../src/components/Blog/blogPost.js?__contentFilePath=/opt/build/repo/blog/astanga-ikonok-t-krishnamacharya-1-resz.md" /* webpackChunkName: "component---src-components-blog-blog-post-js-content-file-path-blog-astanga-ikonok-t-krishnamacharya-1-resz-md" */),
  "component---src-components-blog-blog-post-js-content-file-path-blog-astanga-ikonok-t-krishnamacharya-2-resz-md": () => import("./../../../src/components/Blog/blogPost.js?__contentFilePath=/opt/build/repo/blog/astanga-ikonok-t-krishnamacharya-2-resz.md" /* webpackChunkName: "component---src-components-blog-blog-post-js-content-file-path-blog-astanga-ikonok-t-krishnamacharya-2-resz-md" */),
  "component---src-components-blog-blog-post-js-content-file-path-blog-astanga-joga-es-a-mula-bandha-titka-md": () => import("./../../../src/components/Blog/blogPost.js?__contentFilePath=/opt/build/repo/blog/astanga-joga-es-a-mula-bandha-titka.md" /* webpackChunkName: "component---src-components-blog-blog-post-js-content-file-path-blog-astanga-joga-es-a-mula-bandha-titka-md" */),
  "component---src-components-blog-blog-post-js-content-file-path-blog-astanga-joga-segit-a-noknek-hogy-erosebbek-legyenek-md": () => import("./../../../src/components/Blog/blogPost.js?__contentFilePath=/opt/build/repo/blog/astanga-joga-segit-a-noknek-hogy-erosebbek-legyenek.md" /* webpackChunkName: "component---src-components-blog-blog-post-js-content-file-path-blog-astanga-joga-segit-a-noknek-hogy-erosebbek-legyenek-md" */),
  "component---src-components-blog-blog-post-js-content-file-path-blog-astanga-triszthana-md": () => import("./../../../src/components/Blog/blogPost.js?__contentFilePath=/opt/build/repo/blog/astanga-triszthana.md" /* webpackChunkName: "component---src-components-blog-blog-post-js-content-file-path-blog-astanga-triszthana-md" */),
  "component---src-components-blog-blog-post-js-content-file-path-blog-astanganyu-nagycsalados-gyakorlas-md": () => import("./../../../src/components/Blog/blogPost.js?__contentFilePath=/opt/build/repo/blog/astanganyu-nagycsalados-gyakorlas.md" /* webpackChunkName: "component---src-components-blog-blog-post-js-content-file-path-blog-astanganyu-nagycsalados-gyakorlas-md" */),
  "component---src-components-blog-blog-post-js-content-file-path-blog-astangapu-nagycsalados-gyakorlas-md": () => import("./../../../src/components/Blog/blogPost.js?__contentFilePath=/opt/build/repo/blog/astangapu-nagycsalados-gyakorlas.md" /* webpackChunkName: "component---src-components-blog-blog-post-js-content-file-path-blog-astangapu-nagycsalados-gyakorlas-md" */),
  "component---src-components-blog-blog-post-js-content-file-path-blog-az-astanga-muveszete-md": () => import("./../../../src/components/Blog/blogPost.js?__contentFilePath=/opt/build/repo/blog/az-astanga-muveszete.md" /* webpackChunkName: "component---src-components-blog-blog-post-js-content-file-path-blog-az-astanga-muveszete-md" */),
  "component---src-components-blog-blog-post-js-content-file-path-blog-az-astanga-vinyasza-joga-nem-hatha-joga-md": () => import("./../../../src/components/Blog/blogPost.js?__contentFilePath=/opt/build/repo/blog/az-astanga-vinyasza-joga-nem-hatha-joga.md" /* webpackChunkName: "component---src-components-blog-blog-post-js-content-file-path-blog-az-astanga-vinyasza-joga-nem-hatha-joga-md" */),
  "component---src-components-blog-blog-post-js-content-file-path-blog-az-elet-ertelme-md": () => import("./../../../src/components/Blog/blogPost.js?__contentFilePath=/opt/build/repo/blog/az-elet-ertelme.md" /* webpackChunkName: "component---src-components-blog-blog-post-js-content-file-path-blog-az-elet-ertelme-md" */),
  "component---src-components-blog-blog-post-js-content-file-path-blog-charles-secallus-a-popjoga-kultura-md": () => import("./../../../src/components/Blog/blogPost.js?__contentFilePath=/opt/build/repo/blog/charles-secallus-a-popjoga-kultura.md" /* webpackChunkName: "component---src-components-blog-blog-post-js-content-file-path-blog-charles-secallus-a-popjoga-kultura-md" */),
  "component---src-components-blog-blog-post-js-content-file-path-blog-david-garrigues-gyenge-testben-erotlen-lelek-md": () => import("./../../../src/components/Blog/blogPost.js?__contentFilePath=/opt/build/repo/blog/david-garrigues-gyenge-testben-erotlen-lelek.md" /* webpackChunkName: "component---src-components-blog-blog-post-js-content-file-path-blog-david-garrigues-gyenge-testben-erotlen-lelek-md" */),
  "component---src-components-blog-blog-post-js-content-file-path-blog-eddie-stern-a-joga-csupan-az-addikcio-egy-mas-formaja-1-md": () => import("./../../../src/components/Blog/blogPost.js?__contentFilePath=/opt/build/repo/blog/eddie-stern-a-joga-csupan-az-addikcio-egy-mas-formaja-1.md" /* webpackChunkName: "component---src-components-blog-blog-post-js-content-file-path-blog-eddie-stern-a-joga-csupan-az-addikcio-egy-mas-formaja-1-md" */),
  "component---src-components-blog-blog-post-js-content-file-path-blog-eddie-stern-hol-van-az-elmem-md": () => import("./../../../src/components/Blog/blogPost.js?__contentFilePath=/opt/build/repo/blog/eddie-stern-hol-van-az-elmem.md" /* webpackChunkName: "component---src-components-blog-blog-post-js-content-file-path-blog-eddie-stern-hol-van-az-elmem-md" */),
  "component---src-components-blog-blog-post-js-content-file-path-blog-elmelkedesek-es-gondolatok-az-uj-shalarol-es-az-otodik-mysore-i-sharath-joisnal-toltott-gyakorloutamrol-iain-grysak-md": () => import("./../../../src/components/Blog/blogPost.js?__contentFilePath=/opt/build/repo/blog/elmelkedesek-es-gondolatok-az-uj-shalarol-es-az-otodik-mysore-i-sharath-joisnal-toltott-gyakorloutamrol-iain-grysak.md" /* webpackChunkName: "component---src-components-blog-blog-post-js-content-file-path-blog-elmelkedesek-es-gondolatok-az-uj-shalarol-es-az-otodik-mysore-i-sharath-joisnal-toltott-gyakorloutamrol-iain-grysak-md" */),
  "component---src-components-blog-blog-post-js-content-file-path-blog-exploring-25-years-of-ashtanga-with-ross-stambaugh-the-journey-where-all-roads-lead-to-mysore-md": () => import("./../../../src/components/Blog/blogPost.js?__contentFilePath=/opt/build/repo/blog/exploring-25-years-of-ashtanga-with-ross-stambaugh-the-journey-where-all-roads-lead-to-mysore.md" /* webpackChunkName: "component---src-components-blog-blog-post-js-content-file-path-blog-exploring-25-years-of-ashtanga-with-ross-stambaugh-the-journey-where-all-roads-lead-to-mysore-md" */),
  "component---src-components-blog-blog-post-js-content-file-path-blog-gondolatok-a-tradicionalis-jogagyakorlas-elmelyiteserol-md": () => import("./../../../src/components/Blog/blogPost.js?__contentFilePath=/opt/build/repo/blog/gondolatok-a-tradicionalis-jogagyakorlas-elmelyiteserol.md" /* webpackChunkName: "component---src-components-blog-blog-post-js-content-file-path-blog-gondolatok-a-tradicionalis-jogagyakorlas-elmelyiteserol-md" */),
  "component---src-components-blog-blog-post-js-content-file-path-blog-isten-megismerese-patandzsali-jogaaforizmai-md": () => import("./../../../src/components/Blog/blogPost.js?__contentFilePath=/opt/build/repo/blog/isten-megismerese-patandzsali-jogaaforizmai.md" /* webpackChunkName: "component---src-components-blog-blog-post-js-content-file-path-blog-isten-megismerese-patandzsali-jogaaforizmai-md" */),
  "component---src-components-blog-blog-post-js-content-file-path-blog-itt-allj-meg-gondolatok-sharath-jois-tanitasarol-es-a-mysore-gyakorlas-modszererol-md": () => import("./../../../src/components/Blog/blogPost.js?__contentFilePath=/opt/build/repo/blog/itt-allj-meg-gondolatok-sharath-jois-tanitasarol-es-a-mysore-gyakorlas-modszererol.md" /* webpackChunkName: "component---src-components-blog-blog-post-js-content-file-path-blog-itt-allj-meg-gondolatok-sharath-jois-tanitasarol-es-a-mysore-gyakorlas-modszererol-md" */),
  "component---src-components-blog-blog-post-js-content-file-path-blog-jollet-a-hatarainkon-avagy-mit-tanulhatunk-a-mysore-stilusu-astanga-joga-napi-szintu-gyakorlasaval-andy-davis-tollabol-md": () => import("./../../../src/components/Blog/blogPost.js?__contentFilePath=/opt/build/repo/blog/jollet-a-hatarainkon-avagy-mit-tanulhatunk-a-mysore-stilusu-astanga-joga-napi-szintu-gyakorlasaval-andy-davis-tollabol.md" /* webpackChunkName: "component---src-components-blog-blog-post-js-content-file-path-blog-jollet-a-hatarainkon-avagy-mit-tanulhatunk-a-mysore-stilusu-astanga-joga-napi-szintu-gyakorlasaval-andy-davis-tollabol-md" */),
  "component---src-components-blog-blog-post-js-content-file-path-blog-level-sri-k-pattabhi-jois-tol-md": () => import("./../../../src/components/Blog/blogPost.js?__contentFilePath=/opt/build/repo/blog/level-sri-k-pattabhi-jois-tol.md" /* webpackChunkName: "component---src-components-blog-blog-post-js-content-file-path-blog-level-sri-k-pattabhi-jois-tol-md" */),
  "component---src-components-blog-blog-post-js-content-file-path-blog-megosztani-a-tudast-megosztani-a-hatalmat-md": () => import("./../../../src/components/Blog/blogPost.js?__contentFilePath=/opt/build/repo/blog/megosztani-a-tudast-megosztani-a-hatalmat.md" /* webpackChunkName: "component---src-components-blog-blog-post-js-content-file-path-blog-megosztani-a-tudast-megosztani-a-hatalmat-md" */),
  "component---src-components-blog-blog-post-js-content-file-path-blog-miert-nem-gyakorlunk-teli-es-ujholdkor-astanga-vinyasza-jogat-md": () => import("./../../../src/components/Blog/blogPost.js?__contentFilePath=/opt/build/repo/blog/miert-nem-gyakorlunk-teli-es-ujholdkor-astanga-vinyasza-jogat.md" /* webpackChunkName: "component---src-components-blog-blog-post-js-content-file-path-blog-miert-nem-gyakorlunk-teli-es-ujholdkor-astanga-vinyasza-jogat-md" */),
  "component---src-components-blog-blog-post-js-content-file-path-blog-mysore-i-naplo-torok-peter-tollabol-md": () => import("./../../../src/components/Blog/blogPost.js?__contentFilePath=/opt/build/repo/blog/mysore-i-naplo-torok-peter-tollabol.md" /* webpackChunkName: "component---src-components-blog-blog-post-js-content-file-path-blog-mysore-i-naplo-torok-peter-tollabol-md" */),
  "component---src-components-blog-blog-post-js-content-file-path-blog-olvasonaplo-avagy-miert-jarunk-mysore-ba-md": () => import("./../../../src/components/Blog/blogPost.js?__contentFilePath=/opt/build/repo/blog/olvasonaplo-avagy-miert-jarunk-mysore-ba.md" /* webpackChunkName: "component---src-components-blog-blog-post-js-content-file-path-blog-olvasonaplo-avagy-miert-jarunk-mysore-ba-md" */),
  "component---src-components-blog-blog-post-js-content-file-path-blog-rendszerelmeletu-szemleletmod-a-fajdalom-feloldasara-astanga-gyakorlok-szamara-md": () => import("./../../../src/components/Blog/blogPost.js?__contentFilePath=/opt/build/repo/blog/rendszerelmeletu-szemleletmod-a-fajdalom-feloldasara-astanga-gyakorlok-szamara.md" /* webpackChunkName: "component---src-components-blog-blog-post-js-content-file-path-blog-rendszerelmeletu-szemleletmod-a-fajdalom-feloldasara-astanga-gyakorlok-szamara-md" */),
  "component---src-components-blog-blog-post-js-content-file-path-blog-sharath-jois-arrol-hogy-miben-nyujt-tobbet-egy-helyi-shala-mint-egy-hagyomanyos-edzes-md": () => import("./../../../src/components/Blog/blogPost.js?__contentFilePath=/opt/build/repo/blog/sharath-jois-arrol-hogy-miben-nyujt-tobbet-egy-helyi-shala-mint-egy-hagyomanyos-edzes.md" /* webpackChunkName: "component---src-components-blog-blog-post-js-content-file-path-blog-sharath-jois-arrol-hogy-miben-nyujt-tobbet-egy-helyi-shala-mint-egy-hagyomanyos-edzes-md" */),
  "component---src-components-blog-blog-post-js-content-file-path-blog-tippek-es-trukkok-az-otthoni-gyakorlashoz-md": () => import("./../../../src/components/Blog/blogPost.js?__contentFilePath=/opt/build/repo/blog/tippek-es-trukkok-az-otthoni-gyakorlashoz.md" /* webpackChunkName: "component---src-components-blog-blog-post-js-content-file-path-blog-tippek-es-trukkok-az-otthoni-gyakorlashoz-md" */),
  "component---src-components-blog-blog-post-js-content-file-path-blog-tuzceremonia-az-uj-bandha-works-ben-md": () => import("./../../../src/components/Blog/blogPost.js?__contentFilePath=/opt/build/repo/blog/tuzceremonia-az-uj-bandha-works-ben.md" /* webpackChunkName: "component---src-components-blog-blog-post-js-content-file-path-blog-tuzceremonia-az-uj-bandha-works-ben-md" */),
  "component---src-components-blog-blog-post-js-content-file-path-blog-ures-gyomor-egy-problema-teli-gyomor-szaz-problema-md": () => import("./../../../src/components/Blog/blogPost.js?__contentFilePath=/opt/build/repo/blog/ures-gyomor-egy-problema-teli-gyomor-szaz-problema.md" /* webpackChunkName: "component---src-components-blog-blog-post-js-content-file-path-blog-ures-gyomor-egy-problema-teli-gyomor-szaz-problema-md" */),
  "component---src-components-blog-tags-js": () => import("./../../../src/components/Blog/tags.js" /* webpackChunkName: "component---src-components-blog-tags-js" */),
  "component---src-components-orarend-js": () => import("./../../../src/components/orarend.js" /* webpackChunkName: "component---src-components-orarend-js" */),
  "component---src-components-schedule-js": () => import("./../../../src/components/schedule.js" /* webpackChunkName: "component---src-components-schedule-js" */),
  "component---src-pages-108-napudvozlet-js": () => import("./../../../src/pages/108-napudvozlet.js" /* webpackChunkName: "component---src-pages-108-napudvozlet-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-adatvedelmi-tajekoztato-js": () => import("./../../../src/pages/adatvedelmi-tajekoztato.js" /* webpackChunkName: "component---src-pages-adatvedelmi-tajekoztato-js" */),
  "component---src-pages-ajanlottolvasmanyok-js": () => import("./../../../src/pages/ajanlottolvasmanyok.js" /* webpackChunkName: "component---src-pages-ajanlottolvasmanyok-js" */),
  "component---src-pages-arak-js": () => import("./../../../src/pages/arak.js" /* webpackChunkName: "component---src-pages-arak-js" */),
  "component---src-pages-ashtanga-mysore-program-js": () => import("./../../../src/pages/ashtanga-mysore-program.js" /* webpackChunkName: "component---src-pages-ashtanga-mysore-program-js" */),
  "component---src-pages-astanga-kezdo-tanfolyam-js": () => import("./../../../src/pages/astanga-kezdo-tanfolyam.js" /* webpackChunkName: "component---src-pages-astanga-kezdo-tanfolyam-js" */),
  "component---src-pages-astanga-mysore-kurzus-js": () => import("./../../../src/pages/astanga-mysore-kurzus.js" /* webpackChunkName: "component---src-pages-astanga-mysore-kurzus-js" */),
  "component---src-pages-astanga-mysore-program-js": () => import("./../../../src/pages/astanga-mysore-program.js" /* webpackChunkName: "component---src-pages-astanga-mysore-program-js" */),
  "component---src-pages-barna-kisanna-js": () => import("./../../../src/pages/barna-kisanna.js" /* webpackChunkName: "component---src-pages-barna-kisanna-js" */),
  "component---src-pages-bimba-andras-imre-js": () => import("./../../../src/pages/bimba-andras-imre.js" /* webpackChunkName: "component---src-pages-bimba-andras-imre-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-csapatunk-js": () => import("./../../../src/pages/csapatunk.js" /* webpackChunkName: "component---src-pages-csapatunk-js" */),
  "component---src-pages-csermelyi-voros-rozsa-js": () => import("./../../../src/pages/csermelyi-voros-rozsa.js" /* webpackChunkName: "component---src-pages-csermelyi-voros-rozsa-js" */),
  "component---src-pages-development-page-js": () => import("./../../../src/pages/development-page.js" /* webpackChunkName: "component---src-pages-development-page-js" */),
  "component---src-pages-elso-alkalom-js": () => import("./../../../src/pages/elso-alkalom.js" /* webpackChunkName: "component---src-pages-elso-alkalom-js" */),
  "component---src-pages-english-js": () => import("./../../../src/pages/english.js" /* webpackChunkName: "component---src-pages-english-js" */),
  "component---src-pages-gal-noemi-andrea-js": () => import("./../../../src/pages/gal-noemi-andrea.js" /* webpackChunkName: "component---src-pages-gal-noemi-andrea-js" */),
  "component---src-pages-galeria-js": () => import("./../../../src/pages/galeria.js" /* webpackChunkName: "component---src-pages-galeria-js" */),
  "component---src-pages-hazirend-js": () => import("./../../../src/pages/hazirend.js" /* webpackChunkName: "component---src-pages-hazirend-js" */),
  "component---src-pages-hirlevel-js": () => import("./../../../src/pages/hirlevel.js" /* webpackChunkName: "component---src-pages-hirlevel-js" */),
  "component---src-pages-holdnapok-js": () => import("./../../../src/pages/holdnapok.js" /* webpackChunkName: "component---src-pages-holdnapok-js" */),
  "component---src-pages-horvath-janka-js": () => import("./../../../src/pages/horvath-janka.js" /* webpackChunkName: "component---src-pages-horvath-janka-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kapcsolat-js": () => import("./../../../src/pages/kapcsolat.js" /* webpackChunkName: "component---src-pages-kapcsolat-js" */),
  "component---src-pages-konferencia-js": () => import("./../../../src/pages/konferencia.js" /* webpackChunkName: "component---src-pages-konferencia-js" */),
  "component---src-pages-letoltesek-js": () => import("./../../../src/pages/letoltesek.js" /* webpackChunkName: "component---src-pages-letoltesek-js" */),
  "component---src-pages-mantra-js": () => import("./../../../src/pages/mantra.js" /* webpackChunkName: "component---src-pages-mantra-js" */),
  "component---src-pages-message-sent-english-js": () => import("./../../../src/pages/message-sent-english.js" /* webpackChunkName: "component---src-pages-message-sent-english-js" */),
  "component---src-pages-message-sent-js": () => import("./../../../src/pages/message-sent.js" /* webpackChunkName: "component---src-pages-message-sent-js" */),
  "component---src-pages-mysore-application-english-js": () => import("./../../../src/pages/mysore-application-english.js" /* webpackChunkName: "component---src-pages-mysore-application-english-js" */),
  "component---src-pages-mysore-application-js": () => import("./../../../src/pages/mysore-application.js" /* webpackChunkName: "component---src-pages-mysore-application-js" */),
  "component---src-pages-mysore-program-jelentkezes-js": () => import("./../../../src/pages/mysore-program-jelentkezes.js" /* webpackChunkName: "component---src-pages-mysore-program-jelentkezes-js" */),
  "component---src-pages-mysore-programme-application-js": () => import("./../../../src/pages/mysore-programme-application.js" /* webpackChunkName: "component---src-pages-mysore-programme-application-js" */),
  "component---src-pages-osztondij-js": () => import("./../../../src/pages/osztondij.js" /* webpackChunkName: "component---src-pages-osztondij-js" */),
  "component---src-pages-parampara-js": () => import("./../../../src/pages/parampara.js" /* webpackChunkName: "component---src-pages-parampara-js" */),
  "component---src-pages-prices-js": () => import("./../../../src/pages/prices.js" /* webpackChunkName: "component---src-pages-prices-js" */),
  "component---src-pages-programok-js": () => import("./../../../src/pages/programok.js" /* webpackChunkName: "component---src-pages-programok-js" */),
  "component---src-pages-rolunk-js": () => import("./../../../src/pages/rolunk.js" /* webpackChunkName: "component---src-pages-rolunk-js" */),
  "component---src-pages-subscription-sent-js": () => import("./../../../src/pages/subscription-sent.js" /* webpackChunkName: "component---src-pages-subscription-sent-js" */),
  "component---src-pages-szalai-istvan-js": () => import("./../../../src/pages/szalai-istvan.js" /* webpackChunkName: "component---src-pages-szalai-istvan-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-pages-tanfolyam-js": () => import("./../../../src/pages/tanfolyam.js" /* webpackChunkName: "component---src-pages-tanfolyam-js" */),
  "component---src-pages-taplalkozas-js": () => import("./../../../src/pages/taplalkozas.js" /* webpackChunkName: "component---src-pages-taplalkozas-js" */),
  "component---src-pages-temu-js": () => import("./../../../src/pages/temu.js" /* webpackChunkName: "component---src-pages-temu-js" */),
  "component---src-pages-ui-library-js": () => import("./../../../src/pages/ui-library.js" /* webpackChunkName: "component---src-pages-ui-library-js" */),
  "component---src-pages-vinyasza-jogairanyzatok-js": () => import("./../../../src/pages/vinyasza-jogairanyzatok.js" /* webpackChunkName: "component---src-pages-vinyasza-jogairanyzatok-js" */)
}

